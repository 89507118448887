//import manager from '@/utils/manager'
import api from '@/utils/api'

export function getVipinfo(id) {
    // return api.post('admin/v1/vip/vipinfo/' + id)
    return api.post('wx/v1/vip/vipinfo/' + id)
}

export function productsList(params) {
    // return api.get('admin/v1/vip/vipinfo/products/', { params:params })
    return api.get('wx/v1/vip/products', { params:params })
}

export function createOrder(params) {
    return api.post('wx/v1/jzac', params)
}

export function pay(params) {
    //  type=1：获取支付链接 type=2：微信支付
    params.type = 2;
    params.project_id = 'gold';
    return api.post('wx/v1/official/common/wxpay', params)
}

// export function commonCreateOrder(params) {
//     return api.post('wx/v1/wolf/common/jzac', params)
// }

export function commonCreateOrder(url,params) {
    return api.post(url, params)
}

//支付包支付或paypel支付
export function payMix(params) {
    // return api.post('wx/v1/pay/mix', params)
    return api.post('wx/v1/official_account/pay', params)
}

//公众号微信支付
export function payWxRedirect(orderNum) {
    //    最新版公众号微信支付  type=1：获取支付链接 type=2：微信支付
    return api.post('wx/v1/official/common/wxpay', { order_num: orderNum, type: 1, project_id: 'gold' })
}

//  mycard台湾支付
export function payMycard(params) {
    return api.post('mycardPay/pay', params)
}

export function payLianLian(params) {
    return api.post('lianLianPay/pay', params)
}
